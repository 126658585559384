<template>
  <div class="action-wrapper relative-position">
    <section
      id="action"
      class="section"
    >
      <div
        v-t="'text.successStory'"
        class="title q-py-xl"
      />
      <div
        v-t="'text.keepThemWaiting'"
        class="desc"
      />
      <LandingBtn
        href="https://www.digistore24.com/product/470288"
        :label="$t('button.startNow')"
        class="text-20-700 q-mt-xl"
        text-color="black"
      />

    </section>
    <q-img
      class="bg-img absolute-top-left fit"
      src="~assets/landing/action.svg"
    />
  </div>
</template>

<script>
import LandingBtn from 'pages/landing/LandingBtn.vue';

export default {
  name: 'ActionBlock',
  components: { LandingBtn },
};
</script>

<style scoped lang="scss">
.action-wrapper {
  .bg-img {
    z-index: 0;

    @media screen and (max-width: $breakpoint-xs) {
      position: relative;
    }
  }

  #action {
    padding-top: 60px;
    padding-bottom: 140px;
    background-size: cover;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $breakpoint-xs) {
      padding-bottom: 17px;
    }

    .title {
      width: 100%;
      max-width: 550px;
    }

    .desc {
      width: 100%;
      max-width: 300px;
      font-size: 28px;

      @media screen and (max-width: $breakpoint-xs) {
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
      }
    }
  }
}
</style>
