<template>
  <div class="revenue-wrapper">
    <section
      id="revenue"
      class="section"
    >
      <div
        class="title q-mb-lg"
        v-html="$t('title.makeMoreRevenue')"
      />
      <div class="title-border q-mb-lg"/>
      <div
        class="subtitle q-mb-lg"
        v-html="$t('text.withYourWebinars')"
      />
      <div class="subtitle-border q-mb-lg"/>
      <div class="start flex">
        <LandingBtn
          href="https://www.digistore24.com/product/470288"
          :label="$t('button.startNow')"
        />
        <div
          class="start__text text-primary cursor-pointer"
          v-html="$t('text.fromMarketersForMarketers')"
        />
      </div>
    </section>

    <q-img
      v-if="$q.screen.lt.md"
      class="bg-img"
      src="~/assets/landing/screenMobile.svg"
    />
  </div>
</template>

<script>
import LandingBtn from 'pages/landing/LandingBtn.vue';

export default {
  name: 'RevenueBlock',
  components: { LandingBtn },
};
</script>

<style scoped lang="scss">
.revenue-wrapper {
  .bg-img {
    position: relative;
    margin-top: -35%;
  }
}
#revenue {
  background: url('~assets/landing/screen.png') right no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 90px;
  padding-bottom: 160px;

  @media screen and (max-width: $breakpoint-sm) {
    background: transparent;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 20px;
  }

  .title {
    font-size: 107px;
    font-weight: 700;
    line-height: 95px;
    @media screen and (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }

  .title-border,
  .subtitle-border {
    height: 2px;
    background: #000;
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .title-border {
    width: 100%;
    max-width: 570px;
  }

  .subtitle-border {
    width: 58%;
    max-width: 253px;
  }

  .subtitle {
    font-size: 30px;
    font-weight: 700;

    @media (max-width: $breakpoint-xs) {
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
    }
  }

  .start {
    gap: 40px;

    @media screen and (min-width: $breakpoint-sm) and (max-width: 1173px) {
      width: min-content;
    }

    &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: 31px;
      text-align: left;
    }
    @media (max-width: $breakpoint-sm) {
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      gap: 20px;

      &__text {
        font-size: 11px;
        font-weight: 400;
        line-height: 17px;
      }
    }
  }
}
</style>
