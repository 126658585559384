<template>
  <q-header
    id="header"
    class="section flex justify-between items-center bg-white"
  >
    <WebbymizeLogo
      class="logo"
      :to="{ name: ENUMS.ROUTES.LANDING }"
    />
    <template v-if="$q.screen.width > 813">
      <div class="flex items-center q-gutter-x-lg text-12-700 text-black menu">
        <AppButtonLink
          v-for="(item, i) in items"
          :key="i"
          class="link"
          :label="item.text"
          :href="item.to"
        />
      </div>
      <div class="btn-wrapper flex items-center">
        <LandingBtn
          :to="{ name: ENUMS.ROUTES.DASHBOARD.INDEX }"
          :label="$t('button.loginCaps')"
          type="white-bordered"
        />
        <LandingBtn
          href="https://www.digistore24.com/product/470288"
          :label="$t('button.startNow')"
        />
      </div>
    </template>
    <HeaderMenuMobile
      v-else
      :items="items"
    />
  </q-header>
</template>

<script>
import WebbymizeLogo from 'common/components/WebbymizeLogo';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import LandingBtn from 'pages/landing/LandingBtn.vue';
import HeaderMenuMobile from './HeaderMenuMobile.vue';

export default {
  name: 'HeaderBlock',
  components: {
    WebbymizeLogo,
    LandingBtn,
    HeaderMenuMobile,
    AppButtonLink,
  },
  data() {
    return {
      items: [
        { text: this.$t('label.start'), to: '#revenue' },
        { text: this.$t('label.functions'), to: '#functions' },
        { text: this.$t('label.examples'), to: '#forms' },
        { text: this.$t('label.faq'), to: '#faq' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#header {
  padding-top: 12px;
  padding-bottom: 12px;

  .logo {
    @media (max-width: $breakpoint-sm) {
      width: 55px;
      overflow: hidden;
    }
  }

  .menu {
    @media (max-width: $breakpoint-sm) {
      justify-content: center;
    }

    .link {
      color: black !important;
      font-weight: bold;
      text-transform: uppercase !important;
      margin-left: 0;
      width: fit-content;

      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .btn-wrapper {
    gap: 8px;

    ::v-deep {
      .q-btn__wrapper {
        padding: 5px 19px !important;
        width: 128px;
      }
    }
  }
}
</style>
