<template>
  <div class="card border flex justify-center relative-position">
    <q-img
      src="~assets/landing/quotes.svg"
      class="img absolute"
      width="55px"
      height="49px"
    />
    <div
      v-t="review.text"
      class="text text-center"
    />
    <div class="line bg-black"/>
    <div class="full-width flex no-wrap justify-center items-center">
      <q-img
        :src="`/landing/${review.img}.jpg`"
        class="avatar-img"
        width="60px"
        height="60px"
      />
      <div class="q-ml-md">
        <div
          v-t="review.name"
          class="name"
        />
        <div
          v-t="review.desc"
          class="desc text-grey-9"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewsCard',
  props: {
    review: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-img {
  border-radius: 50%;
}
.card {
  font-family: Comic Sans MS;
  border-radius: 30px;
  line-height: 46px;
  flex: 1;
  align-self: normal;
  padding: 70px 100px 61px;

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 40rem;
    margin: 0 auto;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 39px 25px 30px;
  }
}

.img {
  top: -25px;
}

.text {
  max-width: 700px;
  font-size: 26px;
  font-weight: 400;
  line-height: 46px;

  @media screen and (max-width: $breakpoint-xs) {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
  }
}

.line {
  width: 150px;
  height: 2px;
  margin-top: 40px;
  margin-bottom: 40px;
  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 29px;
    margin-bottom: 29px;
  }
}

.name {
  height: 30px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  @media screen and (max-width: $breakpoint-xs) {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
.desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  @media screen and (max-width: $breakpoint-xs) {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
  }
}
</style>
