<template>
  <div class="feature-selector flex justify-between items-center q-gutter-x-lg">
    <div
      class="q-px-md q-pb-lg type-feature type-feature-control"
      @click="featureChange()"
    >
      <q-icon
        name="fl:expand_left"
        size="24px"
      />
    </div>
    <q-scroll-area class="scroll-area">
      <div class="scroll-area__content flex text-17-700 no-wrap">
        <div
          v-for="(form, i) in forms"
          :key="form.name"
          class="q-px-md q-pb-lg type-feature type-feature-item"
          :class="{ active: currentIndex === i }"
          @click="setIndex(i)"
          v-html="form.name"
        />
      </div>
    </q-scroll-area>
    <div
      class="q-px-md q-pb-lg type-feature type-feature-control"
      @click="featureChange(true)"
    >
      <q-icon
        name="fl:expand_right"
        size="24px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormsFeatureSwitcher',
  props: {
    forms: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    currentIndex: 0,
  }),
  methods: {
    featureChange(next = false) {
      const last = this.forms.length - 1;
      let index = 0;
      if (this.currentIndex === 0) {
        index = next ? 1 : last;
      } else if (this.currentIndex === last) {
        index = next ? 0 : last - 1;
      } else {
        index = next ? this.currentIndex + 1 : this.currentIndex - 1;
      }

      this.setIndex(index);
    },
    setIndex(index) {
      this.currentIndex = index;
      this.$emit('change', this.currentIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.feature-selector {
  --height: 56px;

  .scroll-area {
    height: calc(var(--height) - 1px);
    flex: 1;

    &__content {
      margin: 0 auto;
      width: fit-content;
    }

    ::v-deep {
      .q-scrollarea__thumb {
        display: none;
      }
    }
  }

  .type-feature {
    height: var(--height);

    border-bottom: 2px solid #000;
    cursor: pointer;
    transition: all 0.25s ease;

    &:not(.active):hover {
      border-bottom-color: $grey-8;
      color: $grey-8;
    }

    &.active {
      border-bottom-color: $primary;
      color: $primary;
    }

    &-item {
      min-width: 170px;
    }

    &-control {
      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }
}
</style>
