import { render, staticRenderFns } from "./FormsFeatureSwitcher.vue?vue&type=template&id=3d3751c6&scoped=true&"
import script from "./FormsFeatureSwitcher.vue?vue&type=script&lang=js&"
export * from "./FormsFeatureSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./FormsFeatureSwitcher.vue?vue&type=style&index=0&id=3d3751c6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3751c6",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QScrollArea});
