<template>
  <section
    id="functions"
    class="section"
  >
    <div
      v-t="'title.functions'"
      class="title text-center q-mb-lg"
    />
    <component
      :is="$q.screen.gt.sm ? 'FunctionsCardsDesktop' : 'FunctionsCardsMobile'"
      :items="$options.items"
    />
  </section>
</template>

<script>
import FunctionsCardsDesktop from './FunctionsCardsDesktop.vue';
import FunctionsCardsMobile from './FunctionsCardsMobile.vue';

export default {
  name: 'FunctionsBlock',
  components: { FunctionsCardsDesktop, FunctionsCardsMobile },
  items: {
    doLiveWebinarsWithZoom: {
      text: 'text.doLiveWebinarsWithZoom',
      iconName: 'fl:video_light',
    },
    smartAddCalendar: {
      text: 'text.smartAddCalendar',
      iconName: 'fl:calendar-1',
    },
    connectMultipleEmail: {
      text: 'text.connectMultipleEmail',
      iconName: 'fl:widget_add-1',
    },
    bestOptimizationTool: {
      text: 'text.bestOptimizationTool',
      iconName: 'fl:setting_alt_line',
    },
    moreConversionsWithForms: {
      text: 'text.moreConversionsWithForms',
      iconName: 'fl:filter_alt',
    },
    customThankYouPage: {
      text: 'text.customThankYouPage',
      iconName: 'fl:form',
    },
    multiStepFormForMoreConversions: {
      text: 'text.multiStepFormForMoreConversions',
      iconName: 'fl:table-1',
    },
    nextLevelReminders: {
      text: 'text.nextLevelReminders',
      iconName: 'fl:chat_alt_2_light',
    },
  },
};
</script>

<style scoped lang="scss">
#functions {
  padding-top: 40px;
  padding-bottom: 25px;
}
</style>
