<template>
  <div class="feature">
    <div class="item">
      <q-icon
        color="primary"
        class="q-mb-sm"
        :name="iconName"
        size="59px"
      />
      <div
        v-t="text"
        class="feature__text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FunctionsCard',
  props: {
    text: {
      required: true,
      type: String,
    },
    iconName: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.feature {
  min-height: 173px;
  padding: 0 30px 40px;
  margin-bottom: 50px;
  border-bottom: 2px solid #000;
  text-align: center;
  transition: all 1.8s;

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .item {
    transform: translateY(0);
    transition: all 1.8s;
  }

  &:hover {
    border-bottom: 2px solid $primary;

    .item {
      color: $primary;
      transform: translateY(-15px);
    }
  }
}
</style>
