<template>
  <div class="features">
    <img
      class="features-bg-img"
      src="~assets/landing/form_feature.svg"
    >
    <div class="features_wrapper">
      <div
        class="subtitle"
        v-html="form.title"
      />
      <div class="q-mb-lg">
        <div
          v-for="item in form.items"
          :key="item"
          class="item no-wrap"
        >
          <q-icon
            color="primary"
            name="fl:add_ring"
            size="24px"
          />
          <p class="text-grey-8 features__text">
            {{ item }}
          </p>
        </div>
      </div>

      <LandingBtn
        href="https://www.digistore24.com/product/470288"
        :label="$t('button.startNow')"
      />
    </div>
  </div>
</template>

<script>
import LandingBtn from 'pages/landing/LandingBtn.vue';

export default {
  name: 'FormsCard',
  components: { LandingBtn },
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.features {
  margin-bottom: 80px;

  --feature-max-width: 484px;

  @media screen and (max-width: $breakpoint-sm) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .features-bg-img {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    @media screen and (max-width: $breakpoint-sm) {
      height: unset;
      width: 100%;
      max-width: var(--feature-max-width);
      position: unset;
    }
  }

  .features_wrapper {
    width: 100%;
    max-width: var(--feature-max-width);
    position: relative;
    z-index: 1;

    .subtitle {
      font-size: 30px;
      font-weight: 700;
      margin-top: 48px;
      margin-bottom: 48px;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .item {
      display: flex;
      gap: 6px;
      margin-top: 24px;

      @media screen and (max-width: $breakpoint-sm) {
        margin-top: 20px;
      }

      .features__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        @media screen and (max-width: $breakpoint-sm) {
          font-size: 14px;
          font-weight: 400;
          line-height: 23px;
        }
      }
    }
  }
}
</style>
