<template>
  <div class="cards">
    <div class="row q-col-gutter-lg">
      <div class="col-3">
        <FunctionsCard v-bind="items.doLiveWebinarsWithZoom"/>
        <FunctionsCard v-bind="items.smartAddCalendar"/>
      </div>
      <div class="col-6 text-center">
        <q-img
          src="~assets/landing/functions.svg"
          width="500px"
        />
      </div>
      <div class="col-3">
        <FunctionsCard v-bind="items.connectMultipleEmail"/>
        <FunctionsCard v-bind="items.bestOptimizationTool"/>
      </div>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-3">
        <FunctionsCard v-bind="items.moreConversionsWithForms"/>
      </div>
      <div class="col-3">
        <FunctionsCard v-bind="items.customThankYouPage"/>
      </div>
      <div class="col-3">
        <FunctionsCard v-bind="items.multiStepFormForMoreConversions"/>
      </div>
      <div class="col-3">
        <FunctionsCard v-bind="items.nextLevelReminders"/>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionsCard from './FunctionsCard.vue';

export default {
  name: 'FunctionsCardsDesktop',
  components: { FunctionsCard },
  props: {
    items: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss"></style>
