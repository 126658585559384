<template>
  <q-footer
    id="footer"
    class="section"
  >
    <div class="flex justify-between items-center">
      <div class="logo__wrapper">
        <WebbymizeLogo/>
      </div>
      <LandingBtn
        class="btn text-16-700"
        href="https://www.digistore24.com/product/470288"
        :label="$t('button.startNow')"
        text-color="black"
      />
    </div>
    <div class="menu flex justify-between items-center q-mt-lg">
      <div class="nav">
        <a
          v-t="'label.start'"
          href="#revenue"
          class="link text-uppercase text-black q-mr-md"
        />
        <a
          v-t="'label.functions'"
          href="#functions"
          class="link text-uppercase text-black q-mr-md"
        />
        <a
          v-t="'label.examples'"
          href="#forms"
          class="link text-uppercase text-black q-mr-md"
        />
        <a
          v-t="'label.faq'"
          href="#faq"
          class="link text-uppercase text-black q-mr-md"
        />
      </div>

      <div>
        <span
          v-t="'label.forSupport'"
          class="for-support-text text-grey-1 q-mr-md"
        />
        <a
          v-t="'label.supportWebbymize'"
          href="mailto:Support@Webbymize.com"
          class="link text-uppercase text-black"
        />
      </div>
    </div>
    <div class="copyright flex justify-between q-mt-lg">
      <div
        v-t="'label.copyright'"
        class="text-grey-1"
      />
      <div class="flex">
        <a
          v-t="'label.privacyPolicy'"
          href="https://webbymize.com/terms/"
          class="text-grey-1 q-mr-md"
        />
        <a
          v-t="'label.imprint'"
          href="https://webbymize.com/imprint/"
          class="text-grey-1"
        />
      </div>
    </div>
  </q-footer>
</template>

<script>
import WebbymizeLogo from 'common/components/WebbymizeLogo';
import LandingBtn from 'pages/landing/LandingBtn.vue';

export default {
  name: 'FooterBlock',
  components: { WebbymizeLogo, LandingBtn },
};
</script>

<style scoped lang="scss">
#footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: none;

  .logo__wrapper {
    @media screen and (max-width: $breakpoint-xs) {
      width: 55px;
      overflow: hidden;
    }
  }

  .btn {
    ::v-deep {
      .q-btn__wrapper {
        padding: 5px 17px !important;
      }
    }
  }

  .menu {
    min-height: 90px;
    border-top: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;

    .link {
      font-weight: 700;
      text-decoration: none;
    }

    @media screen and (max-width: $breakpoint-xs) {
      display: block;

      .nav {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 30px 0;
      }

      .for-support-text {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .copyright {
    @media screen and (max-width: $breakpoint-xs) {
      display: block;
      margin-bottom: 40px;

      div:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
