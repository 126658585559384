<template>
  <section
    id="forms"
    class="section"
  >
    <div
      v-t="'title.formFeature'"
      class="title text-center q-mb-md"
    />

    <div class="content">
      <q-tab-panels
        v-model="featuresForm"
        animated
      >
        <q-tab-panel
          v-for="form in forms"
          :key="form.value"
          :name="form.value"
        >
          <FormsCard :form="form"/>
        </q-tab-panel>
      </q-tab-panels>

      <FormsFeatureSwitcher
        :forms="forms"
        @change="featuresForm = $event"
      />
    </div>
  </section>
</template>

<script>
import FormsCard from './FormsCard.vue';
import FormsFeatureSwitcher from './FormsFeatureSwitcher.vue';

export default {
  name: 'FormsBlock',
  components: { FormsFeatureSwitcher, FormsCard },
  data() {
    return {
      featuresForm: 0,
      forms: [
        {
          name: this.$t('label.multiStepForms'),
          title: this.$t('text.multiStepFormsThatWillIncrease'),
          items: [
            this.$t('text.helpYourContacts'),
            this.$t('text.decideWhichSteps'),
            this.$t('text.gainValuableLead'),
          ],
          button: this.$t('button.startNow'),
          value: 0,
        },
        {
          name: this.$t('label.whatsAppReminders'),
          title: this.$t('text.pushAndWhatsappReminders'),
          items: [
            this.$t('text.directlyReachOut'),
            this.$t('text.confirmationsAndMultiple'),
            this.$t('text.incrediblyEasyAppLication'),
          ],
          button: this.$t('button.startNow'),
          value: 1,
        },
        {
          name: this.$t('label.fullyCustomizable'),
          title: this.$t('text.unlimitedCustomizations'),
          items: [
            this.$t('text.marketOrProduct'),
            this.$t('text.usePresetTemplates'),
            this.$t('text.letYourCreativity'),
          ],
          button: this.$t('button.startNow'),
          value: 2,
        },
        {
          name: this.$t('label.highSpeedForms'),
          title: this.$t('text.lightningFastLoading'),
          items: [
            this.$t('text.nextLevelSystems'),
            this.$t('text.fastestPerformance'),
            this.$t('text.noMoreLostLeads'),
          ],
          button: this.$t('button.startNow'),
          value: 3,
        },
        {
          name: this.$t('label.customThankYouPageSolution'),
          title: this.$t('text.customizedThankYou'),
          items: [
            this.$t('text.useThankYouPages'),
            this.$t('text.useReadyTemplates'),
            this.$t('text.giveYourLeads'),
          ],
          button: this.$t('button.startNow'),
          value: 4,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#forms {
  border-bottom: 1px solid $grey-2;
  padding-top: 40px;
  padding-bottom: 80px;

  .content {
    @media screen and (max-width: $breakpoint-sm) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
</style>
