<template>
  <section
    id="reviews"
    class="overflow-hidden section"
  >
    <div
      v-t="'title.testimonials'"
      class="title text-center q-mb-md"
    />

    <q-carousel
      v-model="currentReview"
      class="carousel"
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      animated
    >
      <q-carousel-slide
        v-for="(item, i) in chunkedReviews"
        :key="i"
        :name="i + 1"
        class="column no-wrap"
      >
        <div
          class="cards-container row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
        >
          <ReviewsCard
            v-for="(review, j) in item"
            :key="j"
            :review="review"
          />
        </div>
      </q-carousel-slide>
    </q-carousel>

    <div class="row justify-center">
      <q-pagination
        v-model="currentReview"
        :max="chunkedReviews.length"
        direction-links
        size="17px"
        color="black"
        active-text-color="primary"
      />
    </div>
  </section>
</template>

<script>
import { i18n } from 'common/boot/i18n';
import ReviewsCard from './ReviewsCard.vue';

const chunk = (array, size) => {
  if (!Array.isArray(array) || size <= 0) { return []; }

  const chunkedArray = [];
  let index = 0;
  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + size));
    index += size;
  }
  return chunkedArray;
};

export default {
  name: 'ReviewsBlock',
  components: { ReviewsCard },
  reviews: [
    {
      text: i18n.t('text.buildYourReach'),
      name: 'Mike Hager',
      desc: i18n.t('text.NftExperte'),
      img: 'mike-hager1',
    },
    {
      text: i18n.t('text.webbymizeOpensUp'),
      name: 'Ralf Schmitz',
      desc: i18n.t('text.affiliateKing'),
      img: 'ralf-schmitz',
    },
    {
      text: i18n.t('text.spreadYourMessage'),
      name: 'Stephy Beck',
      desc: i18n.t('text.digitalMarketingExpert'),
      img: 'stephy-beck',
    },
    {
      text: i18n.t('text.growOurBusiness'),
      name: 'Claudio & Karo',
      desc: i18n.t('text.founderChifinity'),
      img: 'claudio-karo',
    },
  ],
  data() {
    return {
      currentReview: 1,
    };
  },
  computed: {
    chunkedReviews() {
      const dimension = this.$q.screen.gt.sm ? 2 : 1;
      return chunk(this.$options.reviews, dimension);
    },
  },
};
</script>

<style scoped lang="scss">
#reviews {
  border-bottom: 1px solid $grey-2;
  padding-top: 60px;
  padding-bottom: 80px;

  .carousel {
    height: 100%;
    padding-top: 28px;
    margin-bottom: 61px;

    @media screen and (max-width: $breakpoint-xs) {
      margin-bottom: 31px;
    }

    .cards-container {
      gap: 30px;
    }
  }

  ::v-deep {
    .q-carousel__slide {
      padding: 0;
    }

    .q-panel {
      overflow: initial;
    }

    .q-btn {
      margin: 0;
      background: none !important;
    }

    @media screen and (max-width: $breakpoint-sm) {
      .q-pagination {
        width: 100%;
        max-width: 20rem;
        justify-content: center;
      }
    }

    .q-pagination__content > .q-btn:first-child {
      margin-right: 20px;
      font-size: 8px !important;
    }

    .q-pagination__content > .q-btn .q-btn__wrapper {
      padding: 18px 10px !important;
    }

    @media screen and (max-width: $breakpoint-sm) {
      .q-pagination__middle {
        flex: 1;
        visibility: hidden;
      }
    }

    .q-pagination__middle > .q-btn .q-btn__wrapper {
      padding: 10px 2px !important;
    }

    .q-pagination__content {
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }

      & > .q-btn:last-child {
        margin-left: 20px;
        font-size: 8px !important;
      }
    }

    .q-btn__wrapper::before {
      box-shadow: none;
    }

    .q-focus-helper {
      opacity: 0;
      background: none;
    }

    .q-btn__wrapper {
      border-bottom: 2px solid;
      border-radius: 0;
    }
  }
}
</style>
