<template>
  <div>
    <LandingBtn
      class="q-mr-sm"
      :to="{ name: ENUMS.ROUTES.DASHBOARD.INDEX }"
      :label="$t('button.loginCaps')"
      type="white"
    />
    <q-btn
      flat
      dense
      icon="menu"
      size="20px"
      color="black"
      @click="isOpen = true"
    />

    <q-dialog
      v-model="isOpen"
      persistent
      maximized
      transition-show="slide-down"
      transition-hide="slide-up"
    >
      <q-card class="bg-primary text-white section">
        <div class="bar flex no-wrap items-center">
          <WebbymizeLogo
            class="logo"
            :to="{ name: ENUMS.ROUTES.LANDING }"
            :src="$options.whiteLogo"
          />
          <q-space/>
          <LandingBtn
            class="a-btn"
            href="https://www.digistore24.com/product/470288"
            :label="$t('button.startNow')"
            type="white"
          />
          <q-btn
            v-close-popup
            class="close-btn"
            dense
            flat
            icon="close"
            color="black"
          />
        </div>

        <div class="content">
          <AppButtonLink
            v-for="(item, i) in items"
            :key="i"
            class="link"
            :label="item.text"
            :href="item.to"
            @click="isOpen = false"
          />
          <LandingBtn
            :to="{ name: ENUMS.ROUTES.DASHBOARD.INDEX }"
            :label="$t('button.loginCaps')"
            type="white"
          />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import whiteLogo from 'assets/landing/whiteLogo.svg';
import WebbymizeLogo from 'common/components/WebbymizeLogo';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import LandingBtn from 'pages/landing/LandingBtn.vue';

export default {
  name: 'HeaderMenuMobile',
  components: {
    LandingBtn,
    WebbymizeLogo,
    AppButtonLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  whiteLogo,
  data: () => ({
    isOpen: false,
  }),
};
</script>

<style scoped lang="scss">
.section {
  padding-bottom: 32px;

  .bar {
    padding-top: 13px;
    padding-bottom: 13px;

    .logo {
      width: 55px;
      overflow: hidden;
      ::v-deep {
        .logo {
          width: 55px;
        }
      }
    }
    .close-btn {
      margin-left: 20px;
    }
  }

  .a-btn::v-deep .q-btn__wrapper {
    padding: 5px 19px !important;
  }

  .content {
    padding: 35px 0;

    .link {
      font-weight: bold;
      display: block;
      margin-bottom: 30px;

      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      color: black !important;
      font-weight: bold;
      text-transform: uppercase !important;
      margin-left: 0;
      width: fit-content;

      ::v-deep .q-btn__wrapper {
        padding: 0 !important;
      }
    }
  }
}
</style>
