<template>
  <div class="card col-2 flex justify-center">
    <q-img
      :src="src"
      width="150px"
      height="110px"
      contain
    />
  </div>
</template>

<script>
export default {
  name: 'PartnersCard',
  props: {
    src: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  margin: 0 10px;
  filter: grayscale(100%);
  transition: 0.5s;

  &:hover {
    filter: grayscale(0);
  }
}
</style>
