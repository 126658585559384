<template>
  <q-scroll-area class="scroll-area">
    <div class="scroll-area__content">
      <slot/>
    </div>
  </q-scroll-area>
</template>

<script>
export default {
  name: 'PartnersCardsMobile',
};
</script>

<style scoped lang="scss">
.scroll-area {
  height: 150px;

  &__content {
    display: flex;

    ::v-deep {
      .card:not(:last-child) {
        border-right: 1px solid $grey-2;
      }
    }
  }

  ::v-deep {
    .q-scrollarea__thumb {
      display: none;
    }
  }
}
</style>
