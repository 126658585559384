<template>
  <AppButton
    v-bind="{ ...$attrs, ...style }"
    :class="['a-btn', type]"
    padding="20px 50px"
  />
</template>

<script>
import AppButton from 'common/components/buttons/AppButton';

export default {
  name: 'LandingBtn',
  components: { AppButton },
  props: {
    type: {
      type: String,
      default: 'green',
      validator: (value) => ['green', 'white-bordered', 'white'].includes(value),
    },
  },
  computed: {
    style() {
      if (this.type === 'white-bordered') {
        return {
          textColor: 'primary',
          color: 'white',
        };
      }
      if (this.type === 'white') {
        return {
          textColor: 'black',
          color: 'white',
        };
      }
      if (this.type === 'green') {
        return {
          textColor: 'black',
          color: 'primary',
        };
      }
      return {};
    },
  },
};
</script>

<style scoped lang="scss">
.a-btn {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;

  ::v-deep {
    .q-btn__wrapper {
      @media screen and (max-width: $breakpoint-sm) {
        padding: 16px 27px !important;
      }
    }

    .q-btn__content div {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      @media screen and (max-width: $breakpoint-sm) {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
      }
    }
  }

  &.green {
    padding: 2px;
  }

  &.white-bordered {
    border: solid 2px $primary;
  }
  &.white {
    border: solid 2px $primary;
  }
}
</style>
