<template>
  <section
    id="partners"
    class="section"
  >
    <div
      v-t="'text.worksEveryPlatform'"
      class="title text-center q-mb-xl"
    />
    <component
      :is="$q.screen.gt.xs ? 'PartnersContainerDesktop' : 'PartnersContainerMobile'"
    >
      <PartnersCard
        v-for="logo in $options.logos"
        :key="logo"
        :src="logo"
      />
    </component>

    <div class="flex justify-center">
      <a
        v-t="'label.supportServices'"
        href="#"
        class="link text-16-400 text-primary q-pt-md q-mt-lg"
      />
    </div>
  </section>
</template>

<script>
import logo1 from 'assets/landing/partners_logo1.png';
import logo2 from 'assets/landing/partners_logo2.png';
import logo3 from 'assets/landing/partners_logo3.png';
import logo4 from 'assets/landing/partners_logo4.png';
import logo5 from 'assets/landing/partners_logo5.png';
import logo6 from 'assets/landing/partners_logo6.png';
import logo7 from 'assets/landing/partners_logo7.png';
import logo8 from 'assets/landing/partners_logo8.png';
import PartnersCard from './PartnersCard.vue';
import PartnersContainerDesktop from './PartnersContainerDesktop.vue';
import PartnersContainerMobile from './PartnersContainerMobile.vue';

export default {
  name: 'PartnersBlock',
  components: {
    PartnersCard,
    PartnersContainerMobile,
    PartnersContainerDesktop,
  },
  logos: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8],
};
</script>

<style scoped lang="scss">
#partners {
  border-bottom: 1px solid $grey-2;
  padding-top: 60px;
  padding-bottom: 80px;

  .link {
    text-decoration: none;
  }
}
</style>
