import { render, staticRenderFns } from "./PartnersContainerMobile.vue?vue&type=template&id=1b3c55bb&scoped=true&"
import script from "./PartnersContainerMobile.vue?vue&type=script&lang=js&"
export * from "./PartnersContainerMobile.vue?vue&type=script&lang=js&"
import style0 from "./PartnersContainerMobile.vue?vue&type=style&index=0&id=1b3c55bb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b3c55bb",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea});
