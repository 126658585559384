<template>
  <div class="cards-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PartnersContainerDesktop',
};
</script>

<style scoped lang="scss">
.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  row-gap: 24px;

  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  ::v-deep {
    @mixin bordered($n) {
      .card:not(:nth-child(#{$n})) {
        border-right: 1px solid $grey-2;
      }
    }
    @media screen and (min-width: $breakpoint-sm) {
      @include bordered(4n);
    }

    @media screen and (max-width: $breakpoint-sm) {
      @include bordered(2n);
    }
  }
}
</style>
