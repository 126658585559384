<template>
  <div>
    <div class="col-6 text-center">
      <q-img
        class="cards-img"
        src="~assets/landing/functions.svg"
      />
    </div>
    <q-scroll-area class="scroll-area">
      <div class="row no-wrap">
        <FunctionsCard
          v-for="(item, i) in items"
          v-bind="item"
          :key="i"
          class="feature"
        />
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
import FunctionsCard from './FunctionsCard.vue';

export default {
  name: 'FunctionsCardsMobile',
  components: { FunctionsCard },
  props: {
    items: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.cards-img {
  min-width: 260px;
  max-width: 500px;
}

.scroll-area {
  height: 256px;

  ::v-deep {
    .q-scrollarea__content {
      padding-top: 16px;
    }
    .q-scrollarea__thumb {
      display: none;
    }
  }
}

.feature {
  width: 175px;
  margin-right: 30px;
  margin-bottom: 0;

  ::v-deep {
    height: unset;
  }
}
</style>
